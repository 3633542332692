<template>
    <div class="huiwen-container huiwen-left huiwen-layout-flex-lr-center" :style="'background-color: #f5f5f6;min-height:' + winSize[1] + 'px;'">
        <div :style="'width: 1200px;min-height:' + winSize[1] + 'px;'" class="huiwen-margin-top-10 huiwen-bg-white">

            <a-row v-if="article_detail && article_detail.title">
                <a-col :span="24" class="huiwen-text-big-title huiwen-text-bold" style="line-height: 30px;margin-top:10px;">{{ article_detail.title }}</a-col>
            </a-row>

            <a-row>
                <a-col v-if="article_detail" :span="24">
                    <div class="huiwen-container" style="height: 20px; padding:20px;box-sizing: border-box;">
                        <div class="huiwen-left  huiwen-text-gary-light" style="height: 20px;line-height: 20px">
                            办税指南 > {{ article_detail.kdbtype }}
                        </div>
                        <div class="huiwen-right" style="height: 20px;line-height: 20px">
                            {{ article_detail.create_time }}
                        </div>
                    </div>
                </a-col>
            </a-row>


            <a-row class="huiwen-margin-top-10 article-content">
                <a-col :span="24" >
                    <template v-if="article_detail && article_detail.content">
                        <div class="huiwen-text-left single-lesson-intro huiwen-bg-white huiwen-padding-all-10 huiwen-border-box" style="line-height: 20px;border:1px solid #f5f5f6;padding:10px 15px;box-sizing: border-box;border-radius: 5px;" v-html="article_detail.content"></div>
                    </template>
                    <template v-else>
                        <a-empty style="padding: 100px 0"/>
                    </template>
                </a-col>
            </a-row>
        </div>
    </div>
</template>

<script>
    import { doQuery, doAction } from "@/api/core"
    import {mapGetters} from 'vuex'

    export default {
        name: "Article",
        data() {
          return {
              article_id: undefined,
              article_detail: null
          }
        },
        mounted() {
            if(this.$route.query && this.$route.query.id) {
                this.article_id = this.$route.query.id
                this.getArticleDetail()
            }
        },
        computed:{
            ...mapGetters([
                'winSize',
            ]),
        },
        methods: {
            getArticleDetail() {
                const that = this
                doQuery({
                    query_code: 'LNT101',
                    param_str1: that.article_id
                }).then(res => {
                    if(res.errcode === that.$consts.biz.OK && res.data) {
                        that.article_detail = res.data[0]

                        that.article_detail.content  = that.article_detail.content.replace(/\&[a-zA-Z;]+;/g,'')
                        document.title = that.article_detail.title;
                    }
                })
            }
        }
    }
</script>

<style scoped>

    /deep/ .article-content p span{
        font-size: 16px !important;
    }
    /deep/ .article-content p{
        font-size: 16px !important;
    }
    /deep/ .article-content img{
        max-width: 100%;
    }
    /deep/ .article-content table{ border:1px solid #8e8e93; border-collapse:collapse;max-width: 100%;}

    /deep/ .article-content table tr td{ border-bottom:1px solid #8e8e93; border-right:1px solid #8e8e93;;line-height:23px; empty-cells:show}

</style>
